<template>
  <div class="Resumepar">
    <div class="top-title">
      <div class="title">
          <span>简历列表</span>/简历详情
      </div>
     
     <div class="top-box">
          <div class="top-head">
            <div class="top-head-img">
               
             <img v-if="listpar.profile_photo !== ''" :src="listpar.profile_photo" alt="" />
            <img v-if="listpar.profile_photo == ''"  src="../../../assets/image/touxiang.png" alt="" />
            </div>
            <span>{{listpar.name}}</span>
          </div>
          <div class="top-money">

          </div>
     </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">姓名:</span><span class="pl-content">{{listpar.true_name}}</span></div>
                  <div class="particulars-list">
          <span class="pl-tltle">昵称:</span><span class="pl-content">{{ listpar.nickname }}</span>
        </div>
          <div class="particulars-list" ><span class="pl-tltle">性别:</span><span class="pl-content">{{listpar.gender}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">手机号:</span><span class="pl-content">{{listpar.mobile}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">身份证号:</span><span class="pl-content">{{listpar.idcard}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">学历:</span><span class="pl-content">{{listpar.edulevel_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">技能:</span><span class="pl-content">{{listpar.work_skills}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">自我评价:</span><span class="pl-content">{{listpar.self_evaluation}}</span></div>
      </div>
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">工作经历:</span><span class="pl-content">{{listpar.work_history}}</span></div>
    </div>
    </div>
      <div class="particulars-img">
          <div class="particulars-list" >
            <span class="pl-tltle">身份证人像面:</span>
             <viewer :images="listpar"> 
            <img :src="listpar.idcard_npic" alt="">
             </viewer>
            </div>
          <div class="particulars-list" >
            <span class="pl-tltle">身份证国徽面:</span>
             <viewer :images="listpar"> 
            <img :src="listpar.idcard_ppic" alt="">
             </viewer>
            </div>
      </div>

  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"

export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,

      listpar:{},
    };
  },

  created(){
    this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'WorkerEntity',
        workerId:this.$route.params.id,
        })
 
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },
  },
  computed: {},
};
</script>


<style  scoped>
@import "../../../assets/css/parstyle.css";

</style>